import { useEffect, useState } from "react";
import ModalBase from "./ModalBase";
import { Grid, TextField, Typography } from "@mui/material";
import ConfirmChangesButtons from "../configuration/ConfirmChangesButtons";
import { updateStatusAlias } from "../../requests/orderRequests";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { IEditStatusModal } from "../../interfaces/order";

const CLOSE_MODAL_DELAY = 300;

const EditStatusModal = ({
  open,
  setOpen,
  status,
  currentAlias,
  fetchStatusAliasList,
}: IEditStatusModal) => {
  const [statusAlias, setStatusAlias] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({} as IAlert);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setStatusAlias("");
      setAlert({} as IAlert);
    }, CLOSE_MODAL_DELAY);
  };

  const handleEditStatusAlias = async () => {
    setLoading(true);

    try {
      const updatedStatus = { ...status, label: statusAlias.trim() };
      await updateStatusAlias(updatedStatus);
      handleClose();
      fetchStatusAliasList();
    } catch (error: any) {
      setAlert({ typeOfAlert: "error", message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) setStatusAlias(currentAlias);
  }, [open]);

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>
        {alert.showAlert && (
          <Grid item xs={12} mb={2}>
            <GenericAlert alert={alert} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography fontSize={20} mb={1}>
            Editar alias
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2.5}>
          <Typography fontSize={14}>
            Personaliza el alias del estado logístico {status.label}.
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3.5}>
          <Typography fontSize={14} mb={1}>
            Alias
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEditStatusAlias();
            }}
          >
            <TextField
              placeholder="Alias"
              fullWidth
              size="small"
              value={statusAlias}
              onChange={(e) => setStatusAlias(e.target.value)}
              inputProps={{ sx: { fontSize: 14 }, maxLength: 46 }}
            />
          </form>
        </Grid>

        <ConfirmChangesButtons
          handleConfirm={handleEditStatusAlias}
          handleCancel={handleClose}
          confirmButtonText="Guardar cambios"
          size={6}
          disableConfirmButton={loading || !statusAlias.trim()}
        />
      </Grid>
    </ModalBase>
  );
};

export default EditStatusModal;
