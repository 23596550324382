import {
  IConfigInitialRules,
  IWebhookInfo,
  TContractOwner,
} from "../interfaces/configuration";
import { PATCH } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";

export const patchContractOwner = (contractOwner: TContractOwner) =>
  fetchData({
    url: "/marketplaces/business_model",
    method: PATCH,
    body: { contractOwner },
  });

export const patchRules = (rules: IConfigInitialRules) =>
  fetchData({
    url: "/marketplaces/rules",
    method: PATCH,
    body: { rules },
  });

export const updateWebhookInfo = ({ useWebhook, stockWebhook }: IWebhookInfo) =>
  fetchData({
    url: "/clients/webhook",
    method: PATCH,
    body: { useWebhook, stockWebhook },
  });
