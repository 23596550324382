import { useState } from "react";
import ModalBase from "./ModalBase";
import { Grid, Typography } from "@mui/material";
import ConfirmChangesButtons from "../configuration/ConfirmChangesButtons";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { ERROR, PATCH } from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { IResetAliasesModal } from "../../interfaces/order";

const ResetAliasesModal = ({
  open,
  setOpen,
  fetchStatusAliasList,
  loading,
}: IResetAliasesModal) => {
  const [alert, setAlert] = useState({} as IAlert);

  const handleClose = () => {
    setOpen(false);
    setAlert({ showAlert: false });
  };

  const handleResetAliases = async () => {
    try {
      await fetchData({
        url: "/orders/customization/alias/reset",
        method: PATCH,
      });
      fetchStatusAliasList();
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setOpen(false);
      setAlert({ showAlert: false });
    }
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>
        {alert.showAlert && (
          <Grid item xs={12} mb={2}>
            <GenericAlert alert={alert} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className="f-s-20 m-t-50 m-b-50 text-center">
            ¿Seguro de que deseas reestablecer todos los alias?
          </Typography>
        </Grid>
        <ConfirmChangesButtons
          handleConfirm={handleResetAliases}
          handleCancel={handleClose}
          confirmButtonText="Confirmar"
          size={6}
          disableConfirmButton={loading}
        />
      </Grid>
    </ModalBase>
  );
};

export default ResetAliasesModal;
