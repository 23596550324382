import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { IOrderDescriptionContent } from "../../interfaces/order";
import {
  MARKETPLACE,
  SPANISH_MODALITIES,
  SPANISH_SERVICES,
} from "../../utils/constants";
import {
  extendStatusIfDelivered,
  getOrderStatus,
  mongoTimeStampToString,
} from "../../utils/orders";
import { UserContext } from "../../contexts/userContext";
import { getFeeString } from "../../utils/text";
import { TEntity } from "../../interfaces/userContext";
// import { getReducedTrackingNumber } from "../../utils/strings";

const reducedInfo = ["origin", "orderNumber", "destination", "type"];
const extendedInfo = [
  "date",
  "status",
  "origin",
  "merchant",
  "packagesQuantity",
  "destination",
  "ol",
  "deliveryPrice",
  "customerDNI",
  "type",
  "externalID",
  "contactNumber",
  "modality",
];
const reducedNonMarketplaceInfo = [
  "origin",
  "type",
  "destination",
  "externalID",
];
const extendedNonMarketplaceInfo = [
  "date",
  "status",
  "origin",
  "ol",
  "packagesQuantity",
  "destination",
  "type",
  "deliveryPrice",
  "customerDNI",
  "modality",
  "externalID",
  "contactNumber",
];

const getOrderDetailDataFields = (entity: TEntity, reduced?: boolean) => {
  if (entity === MARKETPLACE) {
    if (reduced) return reducedInfo;
    return extendedInfo;
  }
  return reduced ? reducedNonMarketplaceInfo : extendedNonMarketplaceInfo;
};

const OrderDescriptionContent = ({
  orderInfo,
  status,
  service,
  merchant,
  external_id,
  customer_document,
  updatedAt,
  retries,
  reduced,
  alias,
}: IOrderDescriptionContent) => {
  const orderStatus = getOrderStatus(status);
  const {
    currentUser: { entity },
  } = useContext(UserContext);

  const MD_GRID_SIZE = reduced ? 6 : 4;
  const SPANISH_SERVICE = SPANISH_SERVICES[service];
  const SPANISH_MODALITY = SPANISH_MODALITIES[orderInfo?.service];
  const orderDescriptionInfo: { [key: string]: any } = {
    date: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Fecha</Typography>
        <Typography>{mongoTimeStampToString(updatedAt, true)}</Typography>
      </Grid>
    ),
    status: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Estado</Typography>
        <Typography>
          {alias || extendStatusIfDelivered(orderStatus?.label, retries)}
        </Typography>
      </Grid>
    ),
    origin: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Origen</Typography>
        <Typography>{orderInfo?.origin}</Typography>
      </Grid>
    ),
    merchant: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Merchant</Typography>
        <Typography>{merchant?.entity.name}</Typography>
      </Grid>
    ),
    packagesQuantity: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">
          Cantidad de paquetes
        </Typography>
        <Typography>{orderInfo?.packages.length}</Typography>
      </Grid>
    ),
    destination: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Destino</Typography>
        <Typography>{orderInfo?.destination}</Typography>
      </Grid>
    ),
    ol: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Operador logístico</Typography>
        <Typography>{orderInfo?.carrier}</Typography>
      </Grid>
    ),
    deliveryPrice: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Costo del envío</Typography>
        <Typography>{getFeeString(orderInfo?.fee)}</Typography>
      </Grid>
    ),
    customerDNI: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">DNI del customer</Typography>
        <Typography>{customer_document || "-"}</Typography>
      </Grid>
    ),
    type: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Tipo</Typography>
        <Typography>{SPANISH_SERVICE}</Typography>
      </Grid>
    ),
    externalID: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">ID externo</Typography>
        <Typography>{external_id || "-"}</Typography>
      </Grid>
    ),
    contactNumber: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Número de contacto</Typography>
        <Typography>{orderInfo?.cellphone || "-"}</Typography>
      </Grid>
    ),
    modality: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">Modalidad</Typography>
        <Typography>{SPANISH_MODALITY}</Typography>
      </Grid>
    ),
    orderNumber: (
      <Grid item xs={12} md={MD_GRID_SIZE}>
        <Typography className="text-bold m-t-10">N° de orden</Typography>
        <Typography>{orderInfo?.trackingNumber}</Typography>
      </Grid>
    ),
  };
  const dataFields = getOrderDetailDataFields(entity, reduced);

  return (
    <Grid container columnSpacing={reduced ? 3 : 0}>
      {dataFields.map((dataField: string) => {
        if (reduced) return orderDescriptionInfo[dataField];
        if (dataField === "modality") {
          return SPANISH_MODALITY && orderDescriptionInfo[dataField];
        }
        if (dataField === "merchant") {
          return (
            merchant &&
            entity === MARKETPLACE &&
            orderDescriptionInfo[dataField]
          );
        }
        return orderDescriptionInfo[dataField];
      })}
    </Grid>
  );
};
export default OrderDescriptionContent;
