import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ListStatuses from "../../components/tables/ListStatuses";
import { ERROR, MEDIUM, ORDER_STATUS } from "../../utils/constants";
import { IOrderStatus, IStatusAliases } from "../../interfaces/order";
import EditStatusModal from "../../components/modals/EditStatusModal";
import { getStatusAliasList } from "../../requests/orderRequests";
import Loader from "../../components/Loader";
import GenericAlert from "../../components/GenericAlert";
import { IAlert } from "../../interfaces/alert";
import ResetAliasesModal from "../../components/modals/ResetStatusAliasesModal";

const AliasCustomization = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({} as IOrderStatus);
  const [statusAliases, setStatusAliases] = useState({} as IStatusAliases);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({} as IAlert);
  const hasAliases = Object.values(statusAliases).some((alias) => alias);

  const handleOpenEditAliasModal = (status: IOrderStatus) => {
    setSelectedStatus(status);
    setOpenEditModal(true);
  };

  const fetchStatusAliasList = async () => {
    setLoading(true);

    try {
      const statusAliasList = await getStatusAliasList();
      setStatusAliases(statusAliasList);
      setAlert({} as IAlert);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusAliasList();
  }, []);

  return (
    <>
      <Typography fontSize={20} mb={2}>
        Estados
      </Typography>
      <Grid
        xl={hasAliases ? 10 : 8}
        display="flex"
        justifyContent="space-between"
      >
        <Typography fontSize={14} mb={3.5}>
          Personaliza el alias de tus estados logísticos.
        </Typography>
        {hasAliases && !loading && (
          <Button
            variant="text"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => setOpenResetModal(true)}
            className="f-s-14 m-b-10"
          >
            Reestablecer estados
          </Button>
        )}
      </Grid>
      {alert.showAlert && (
        <Grid item xs={12} mb={3}>
          <GenericAlert alert={alert} />
        </Grid>
      )}
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <>
          <Grid item xs={12} xl={hasAliases ? 10 : 8}>
            <ListStatuses
              statuses={ORDER_STATUS}
              statusAliases={statusAliases}
              hasAliases={hasAliases}
              handleEdit={handleOpenEditAliasModal}
            />
          </Grid>
          <EditStatusModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            status={selectedStatus}
            currentAlias={statusAliases[selectedStatus.id]}
            fetchStatusAliasList={fetchStatusAliasList}
          />
          <ResetAliasesModal
            open={openResetModal}
            setOpen={setOpenResetModal}
            fetchStatusAliasList={fetchStatusAliasList}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default AliasCustomization;
