import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Typography, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { IAlert } from "../../interfaces/alert";
import { IRules, IWebhookInput } from "../../interfaces/configuration";
import { updateWebhookInfo } from "../../requests/configurationRequests";
import {
  AUTOMATIC_STATE_UPDATE,
  ERROR,
  INITIAL_CONFIGURATION_PARTIAL_PATH,
  MARKETPLACE,
  SELECT_MODE,
  SUCCESS,
} from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import ConfirmChangesButtons from "./ConfirmChangesButtons";
import WebhookForm from "./WebhookForm";

const WebhookSettings = ({ handleNext, handleBack }: IRules) => {
  const [useWebhook, setUseWebhook] = useState(AUTOMATIC_STATE_UPDATE);
  const [stockWebhook, setStockWebhook] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({} as IAlert);
  const [baseInfo, setBaseInfo] = useState({} as IWebhookInput);

  const { currentUser, refreshUserData } = useContext(UserContext);

  const isInitialConfig = location.pathname.includes(
    INITIAL_CONFIGURATION_PARTIAL_PATH
  );

  const isMarketplace = currentUser?.entity === MARKETPLACE;

  useEffect(() => {
    if (currentUser) {
      const baseWebhook = currentUser.webhook_info?.url || "";

      setUseWebhook(AUTOMATIC_STATE_UPDATE);
      setStockWebhook(baseWebhook);
      setBaseInfo({ useWebhook: AUTOMATIC_STATE_UPDATE, stockWebhook: baseWebhook });
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateWebhookInfo({
        useWebhook: useWebhook === AUTOMATIC_STATE_UPDATE,
        stockWebhook,
      });
      setAlert({
        typeOfAlert: SUCCESS,
        message: "Configuración de estados guardada con éxito",
        showAlert: true,
      });
      if (handleNext) handleNext();
      refreshUserData();
      setBaseInfo({
        useWebhook,
        stockWebhook: useWebhook ? stockWebhook : baseInfo.stockWebhook,
      });
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setUseWebhook(baseInfo.useWebhook);
    setStockWebhook(baseInfo.stockWebhook || "");
  };

  const unsavedChanges =
    baseInfo.useWebhook !== useWebhook ||
    baseInfo.stockWebhook !== stockWebhook;

  return (
    <>
      <Card
        className={`br-20 ${isInitialConfig ? "bg-input" : "bg-light m-b-40"}`}
        sx={{
          padding: 3,
          width: isInitialConfig ? "560px" : undefined,
          height: isInitialConfig ? "325px" : undefined,
        }}
      >
        <CardContent className="webhookContainer" sx={{ paddingInline: 4.5 }}>
          <>
            <Typography className="f-s-24">Notificación de estados a la tienda</Typography>
            <div className="m-b-20">
              {alert.showAlert && <GenericAlert alert={alert} />}
            </div>
          </>

          <WebhookForm
            webhookInfo={{ useWebhook, stockWebhook }}
            handleInfoChange={(field: string, value: string) => {
              if (field === "useWebhook") setUseWebhook(value);
              else setStockWebhook(value);
            }}
            isInitialConfig={isInitialConfig}
          />

          {isInitialConfig && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              className="m-t-30 webhookSelect"
            >
              {isMarketplace && (
                <Grid item xs={6}>
                  <Button fullWidth variant="outlined" onClick={handleBack}>
                    Volver
                  </Button>
                </Grid>
              )}
              <Grid item xs={isMarketplace ? 6 : 12}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    useWebhook === SELECT_MODE ||
                    (useWebhook === AUTOMATIC_STATE_UPDATE && !stockWebhook)
                  }
                  loading={loading}
                  loadingPosition="end"
                >
                  Continuar
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {/*
        La posición de los botones depende de si el componente se renderiza en la configuración
        inicial o en la sección de configuración, de ahi el uso de la variable
        isInitialConfig como condicional
      */}
      {!isInitialConfig && unsavedChanges && (
        <ConfirmChangesButtons
          handleConfirm={handleSubmit}
          handleCancel={handleCancel}
          confirmButtonText="Guardar cambios"
        />
      )}
    </>
  );
};

export default WebhookSettings;
