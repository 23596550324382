import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { useWindowSize } from "../../customHooks/useWindowSize";
import {
  IOrderPackage,
  IOrderPackageContent,
  IOrderProduct,
} from "../../interfaces/order";
import { MEDIUM_BREAKPOINT } from "../../utils/constants";

const LargePackageDescription = ({
  products,
  sku,
  quantity,
}: {
  products: IOrderProduct[];
  sku?: string;
  quantity?: number;
}) => (
  <>
    <Grid item container className="m-t-20">
      <Grid item md={4}>
        <Typography className="text-bold">SKU</Typography>
      </Grid>
      {products && (
        <Grid item md={4}>
          <Typography className="text-bold">Descripción</Typography>
        </Grid>
      )}
      <Grid item md={4}>
        <Typography className="text-bold">Cantidad</Typography>
      </Grid>
    </Grid>

    {products ? (
      <>
        {products?.map(
          (
            { sku: productSku, quantity: productQuantity, name }: IOrderProduct,
            i: number
          ) => (
            <React.Fragment key={i}>
              <Grid item md={4}>
                {productSku}
              </Grid>
              <Grid item md={4}>
                {name}
              </Grid>
              <Grid item md={4}>
                {productQuantity}
              </Grid>
            </React.Fragment>
          )
        )}
      </>
    ) : (
      <>
        <Grid item md={4}>
          {sku}
        </Grid>
        <Grid item md={4}>
          {quantity}
        </Grid>
      </>
    )}
  </>
);

const ShortPackageDescription = ({
  products,
}: {
  products: IOrderProduct[];
}) => (
  <>
    {products?.map(
      (
        { sku: productSku, quantity: productQuantity, name }: IOrderProduct,
        i: number
      ) => (
        <Grid item container className="m-t-20" key={i}>
          <Grid item xs={12} md={4}>
            <Typography className="text-bold">SKU {productSku}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Cantidad: {productQuantity}</Typography>
          </Grid>
        </Grid>
      )
    )}
  </>
);

const OrderPackageContent = ({ packages }: IOrderPackageContent) => {
  const { width: windowWidth } = useWindowSize();
  return (
    <>
      {packages?.map(
        (
          {
            width,
            weight,
            depth,
            height,
            products,
            sku,
            quantity,
          }: IOrderPackage,
          index: number
        ) => (
          <>
            <Grid container className="m-b-10" key={index}>
              <Grid item xs={12}>
                <Typography className="f-s-20 text-bold">
                  Paquete {index + 1}
                </Typography>
              </Grid>

              <Grid item xs md={4}>
                <Typography className="text-bold">Dimensiones</Typography>
                <Typography>
                  {height} × {weight} × {depth} cm
                </Typography>
              </Grid>
              <Grid item xs md={4}>
                <Typography className="text-bold">Peso</Typography>
                <Typography>{width}kg</Typography>
              </Grid>

              {windowWidth > MEDIUM_BREAKPOINT ? (
                <LargePackageDescription
                  products={products}
                  sku={sku}
                  quantity={quantity}
                />
              ) : (
                <ShortPackageDescription products={products} />
              )}
            </Grid>
            {index + 1 !== packages?.length && (
              <Divider className="m-b-10 m-t-10" />
            )}
          </>
        )
      )}
    </>
  );
};

export default OrderPackageContent;
