import { useState } from "react";
import { Grid, Typography, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EmailCustomization from "./EmailCustomization";
import AliasCustomization from "./AliasCustomization";

const INITIAL_TAB = "statuses";

const Customization = () => {
  const [currentTab, setCurrentTab] = useState(INITIAL_TAB);

  return (
    <Grid container>
      <Typography fontSize={20} mb={3}>
        Personalización
      </Typography>

      <TabContext value={currentTab}>
        <Grid item xs={12}>
          <TabList onChange={(_, newValue) => setCurrentTab(newValue)}>
            <Tab value="statuses" label="Estados" sx={{ paddingInline: 6 }} />
            <Tab value="emails" label="Emails" sx={{ paddingInline: 6 }} />
          </TabList>
        </Grid>

        <Grid item xs={12} className="m-t-30">
          <TabPanel value="statuses">
            <AliasCustomization />
          </TabPanel>

          <TabPanel value="emails">
            <EmailCustomization />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default Customization;
