import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { IListStatuses } from "../../interfaces/order";
import { CUSTOMIZATION_PERMISSION } from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const ListStatuses = ({
  statuses,
  statusAliases,
  hasAliases,
  handleEdit,
}: IListStatuses) => {
  const { can_write } = usePermission(CUSTOMIZATION_PERMISSION);

  const COLUMNS = [
    { label: "Estados logísticos originales" },
    { label: "Tipo" },
    { label: "Descripción" },
  ];
  const ACTIONS_COLUMN = { label: "Acciones" };
  const ALIAS_COLUMN = { label: "Alias" };

  const getHeader = () => {
    if (hasAliases) COLUMNS.splice(1, 0, ALIAS_COLUMN);
    if (can_write) COLUMNS.push(ACTIONS_COLUMN);
    return COLUMNS;
  };

  return (
    <TableContainer component={Paper} className="br-20 bg-light">
      <Table>
        <ListHeader columns={getHeader()} />
        <TableBody>
          {statuses.map((status) => (
            <TableRow key={status.id}>
              <TableCell className="p-l-20">{status.label}</TableCell>
              {hasAliases && <TableCell>{statusAliases[status.id]}</TableCell>}
              <TableCell>{status.type}</TableCell>
              <TableCell>{status.description}</TableCell>
              {can_write && (
                <TableCell width="13%">
                  <Tooltip arrow title="Editar" placement="top">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(status)}
                    >
                      <span className="material-symbols-rounded">edit</span>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListStatuses;
