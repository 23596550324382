import { ISidebarItem } from "../interfaces/sidebar";
import { IPermission, TEntity } from "../interfaces/userContext";
import {
  CONFIGURATION_PERMISSION,
  CUSTOMIZATION_PERMISSION,
  ECOMMERCE,
  ENTITY_ROUTES,
  EXPORT_PERMISSION,
  INTERNAL,
  MANAGMENT_PERMISSION,
  MARKETPLACE,
  MERCHANT,
  ORDERS_PERMISSION,
  SYS_CARRIERS_PERMISSION,
  SYS_CLIENTS_PERMISSION,
  SYS_METRICS_PERMISSION,
  USERS_PERMISSION,
} from "./constants";

const baseSideBarOptions: { [key: string]: ISidebarItem } = {
  [USERS_PERMISSION]: {
    name: "Usuarios y roles",
    icon: "group_add",
    slug: "usuarios-roles",
    priority: 10,
  },
  [CONFIGURATION_PERMISSION]: {
    name: "Configuración",
    icon: "settings",
    slug: "configuracion",
    priority: 30,
  },
  [EXPORT_PERMISSION]: {
    name: "Exportación de órdenes",
    icon: "export_orders",
    isCustomIcon: true,
    slug: "exportar-ordenes",
    priority: 60,
  },
};

const sideBarDiscByInternal = {
  [SYS_CARRIERS_PERMISSION]: {
    name: "Configuración",
    icon: "settings",
    slug: "configuracion",
    priority: 30,
  },
  [EXPORT_PERMISSION]: {
    name: "Exportación de órdenes",
    icon: "export_orders",
    isCustomIcon: true,
    slug: "exportar-ordenes",
    priority: 60,
  },
  [SYS_CLIENTS_PERMISSION]: {
    name: "Gestión de Clientes",
    icon: "add_business",
    slug: "gestion-clientes",
    priority: 70,
  },
  [SYS_METRICS_PERMISSION]: {
    name: "Usuarios y roles",
    icon: "group_add",
    slug: "usuarios-roles",
    priority: 10,
  },
};

const sideBarDiscByMerchant: {
  [key: string]: ISidebarItem | ISidebarItem[];
} = {
  ...baseSideBarOptions,
  [ORDERS_PERMISSION]: [
    {
      name: "Órdenes",
      icon: "list_alt",
      slug: "ordenes",
      priority: 90,
    },
    {
      name: "Descarga de etiquetas",
      icon: "sim_card_download",
      slug: "descargar-etiquetas",
      priority: 80,
    },
    {
      name: "Auditorías",
      icon: "fact_check",
      slug: "auditoria",
      priority: 10,
    },
  ],
};
const sideBarDiscByMkt: {
  [key: string]: ISidebarItem | ISidebarItem[];
} = {
  ...baseSideBarOptions,
  [ORDERS_PERMISSION]: [
    {
      name: "Órdenes",
      icon: "list_alt",
      slug: "ordenes",
      priority: 90,
    },
    {
      name: "Auditorías",
      icon: "fact_check",
      slug: "auditoria",
      priority: 10,
    },
  ],
  [CUSTOMIZATION_PERMISSION]: {
    name: "Personalización",
    icon: "brush",
    slug: "personalizacion",
    priority: 20,
  },
  [MANAGMENT_PERMISSION]: {
    name: "Gestión de Merchants",
    icon: "add_business",
    slug: "gestion-merchants",
    priority: 70,
  },
};
const sideBarDiscByEcommerce: {
  [key: string]: ISidebarItem | ISidebarItem[];
} = {
  ...baseSideBarOptions,
  [ORDERS_PERMISSION]: [
    {
      name: "Órdenes",
      icon: "list_alt",
      slug: "ordenes",
      priority: 90,
    },
    {
      name: "Descarga de etiquetas",
      icon: "sim_card_download",
      slug: "descargar-etiquetas",
      priority: 80,
    },
    {
      name: "Auditorías",
      icon: "fact_check",
      slug: "auditoria",
      priority: 10,
    },
  ],
  [CUSTOMIZATION_PERMISSION]: {
    name: "Personalización",
    icon: "brush",
    slug: "personalizacion",
    priority: 20,
  },
  [MANAGMENT_PERMISSION]: {
    name: "Gestión de Merchants",
    icon: "add_business",
    slug: "gestion-merchants",
    priority: 70,
  },
};

const sideBarByEntity: { [key: string]: any } = {
  [MARKETPLACE]: sideBarDiscByMkt,
  [MERCHANT]: sideBarDiscByMerchant,
  [ECOMMERCE]: sideBarDiscByEcommerce,
  [INTERNAL]: sideBarDiscByInternal
};
export const getSidebarOptions = (
  entity: TEntity,
  permissions: IPermission,
) => {
  const sideBarOptionsPermissions = sideBarByEntity[entity];
  const sideBarOptions = Object.keys(permissions)
    .reduce(
      (accum: ISidebarItem[], key) => {
        // NOTE: Solo marketplace deberia tener la opción Gestion de Merchants habilitada en el SIDEBAR.
        if (key === MANAGMENT_PERMISSION && entity !== MARKETPLACE)
          return accum;
        if (sideBarOptionsPermissions[key])
          return accum.concat(sideBarOptionsPermissions[key]);
        return accum;
      },
      [
        {
          name: "Inicio",
          icon: "other_houses",
          slug: ENTITY_ROUTES[entity],
          priority: 100,
        },
      ]
    )
    .sort(
      (
        { priority: priorityA }: ISidebarItem,
        { priority: priorityB }: ISidebarItem
      ) => priorityB - priorityA
    );
  return sideBarOptions;
};
