import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { IWehbookFormProps } from "../../interfaces/configuration";
import {
  AUTOMATIC_STATE_UPDATE,
  CONFIGURATION_PERMISSION,
  SELECT_MODE,
} from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const WebhookForm = ({
  webhookInfo: { useWebhook, stockWebhook },
  handleInfoChange,
  isInitialConfig,
}: IWehbookFormProps) => {
  const { can_write } = usePermission(CONFIGURATION_PERMISSION);
  return (
    <div className="webhookForm">
      <FormControl size="small" fullWidth>
        <Typography className="m-b-10">
          Elige cómo deseas notificar los estados.
        </Typography>
        <Select
          size="small"
          className="f-s-14 bg-input m-b-20"
          disabled={true}
          value={useWebhook}
          onChange={({ target: { value } }) =>
            handleInfoChange("useWebhook", value)
          }
        >
          {isInitialConfig && (
            <MenuItem
              disabled
              key={SELECT_MODE}
              value={SELECT_MODE}
              className="f-s-14"
            >
              {SELECT_MODE}
            </MenuItem>
          )}
          <MenuItem
            key={AUTOMATIC_STATE_UPDATE}
            value={AUTOMATIC_STATE_UPDATE}
            className="f-s-14"
          >
            {AUTOMATIC_STATE_UPDATE}
          </MenuItem>
        </Select>
      </FormControl>

      {useWebhook === AUTOMATIC_STATE_UPDATE && (
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">
            Por favor ingresa el webhook para la notificación de estados.
          </Typography>
          <OutlinedInput
            autoFocus
            size="small"
            className="bg-input"
            disabled={!can_write}
            value={stockWebhook}
            placeholder="Ingresa el webhook para la notificación de estados"
            onChange={({ target: { value } }) =>
              handleInfoChange("stockWebhook", value)
            }
          />
        </FormControl>
      )}
    </div>
  );
};

export default WebhookForm;
